<template>
  <div class="overlayer">
    <aside class="rightSidebar">
      <div
        class="text-lg flex text-romanSilver font-bold pb-3 "
        v-if="hasTitleSlot"
        style="border-bottom: 0.5px solid #878E99; margin: 1.125rem 0"
      >
        <div @click="$emit('back')">
          <back-button variant="secondary" v-if="backButton" class="p-0" />
        </div>
        <slot name="title" />
      </div>
      <div style="height:100%" v-if="loadingData">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else>
        <div
          v-if="hasSubTitleSlot"
          style="margin: 1.125rem 0; border-bottom: 1px dashed #878E99"
        >
          <slot name="subtitle" />
        </div>
        <slot />
        <div class="footer flex mt-6 mb-3" v-if="showFooter">
          <div v-if="closeButton" class="flex flex-grow">
            <Button
              width="7.875rem"
              @click="$emit('close')"
              class=" font-semibold"
            >
              Close
            </Button>
          </div>
          <div class="flex flex-grow" v-else>
            <Button
              width="7.875rem"
              @click="$emit('submit')"
              :class="`${buttonClass}`"
            >
              {{ submit }}
            </Button>
            <Button width="7.875rem" @click="$emit('close')">
              {{ cancel }}
            </Button>
          </div>
          <div v-if="hasFooter" class="mr-5">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import noScrollMixin from "@/utilities/noScrollMixin";
import Button from "./Button";

export default {
  mixins: [noScrollMixin],
  components: { Button, BackButton },
  props: {
    buttonClass: {
      type: String,
      default: "none"
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    submit: {
      type: String,
      default: "Send"
    },
    closeButton: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: String,
      default: "Cancel"
    },
    loading: {
      type: Boolean,
      default: false
    },
    backButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingData: this.loading
    };
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title;
    },
    hasSubTitleSlot() {
      return !!this.$slots.subtitle;
    },
    hasFooter() {
      return !!this.$slots.footer;
    }
  },
  watch: {
    loading() {
      this.loadingData = this.loading;
    }
  }
};
</script>

<style scoped lang="scss">
.rightSidebar {
  position: absolute;
  top: 7%;
  left: 38%;
  width: 32.5rem;
  height: auto;
  margin-top: -50px;
  margin-left: -50px;
  padding: 0 1.25rem;
  background: #ffffff;
  color: #321c3b;
  box-shadow: 0px 10px 15px rgba(8, 7, 8, 0.1),
    0px 4px 6px rgba(45, 49, 66, 0.05);
  border-radius: 5px;
}
.footer {
  width: 100%;
  text-align: center;
}
.overlayer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow-y: auto;
}
.button {
  padding: 12px, 15px, 12px, 15px;
}
.button:focus {
  outline: none;
}

</style>
